import React, { memo } from "react";
import { Checkbox } from "antd";

import { FormItemProps } from "components/antd/Form";
import { EditKioskConfigFormItem } from "pages/EditKioskConfig/EditKioskConfigForm/useEditKioskConfigForm";

type Props = Omit<FormItemProps, "children">;

export const ShouldPayAtCashRegisterField = memo<Props>((props) => (
  <EditKioskConfigFormItem valuePropName="checked" {...props} name="shouldPayAtCashRegister">
    <Checkbox>レジで会計する</Checkbox>
  </EditKioskConfigFormItem>
));
