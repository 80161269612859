import * as Types from '../../types/graphql.js';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const EditKioskConfigGetShop = gql`
    query EditKioskConfigGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
    name
    kioskConfig {
      id
      shopId
      colorScheme
      color
      homeImageUrl
      shouldPayAtCashRegister
    }
  }
}
    `;
export const EditKioskConfigUpdateKioskConfig = gql`
    mutation EditKioskConfigUpdateKioskConfig($kioskConfig: kioskConfig_insert_input!) {
  insert_kioskConfig_one(
    object: $kioskConfig
    on_conflict: {constraint: kioskConfig_shopId_key, update_columns: [colorScheme, color, homeImageUrl, shouldPayAtCashRegister]}
  ) {
    __typename
  }
}
    `;
export type EditKioskConfigGetShopQueryVariables = Types.Exact<{
  shopId: Types.Scalars['uuid'];
}>;


export type EditKioskConfigGetShopQuery = (
  { __typename?: 'query_root' }
  & { shop_by_pk?: Types.Maybe<(
    { __typename?: 'shop' }
    & Pick<Types.Shop, 'shopId' | 'companyId' | 'name'>
    & { kioskConfig?: Types.Maybe<(
      { __typename?: 'kioskConfig' }
      & Pick<Types.KioskConfig, 'id' | 'shopId' | 'colorScheme' | 'color' | 'homeImageUrl' | 'shouldPayAtCashRegister'>
    )> }
  )> }
);

export type EditKioskConfigUpdateKioskConfigMutationVariables = Types.Exact<{
  kioskConfig: Types.KioskConfigInsertInput;
}>;


export type EditKioskConfigUpdateKioskConfigMutation = (
  { __typename?: 'mutation_root' }
  & { insert_kioskConfig_one?: Types.Maybe<{ __typename: 'kioskConfig' }> }
);


export const EditKioskConfigGetShopDocument = gql`
    query EditKioskConfigGetShop($shopId: uuid!) {
  shop_by_pk(shopId: $shopId) {
    shopId
    companyId
    name
    kioskConfig {
      id
      shopId
      colorScheme
      color
      homeImageUrl
      shouldPayAtCashRegister
    }
  }
}
    `;

/**
 * __useEditKioskConfigGetShopQuery__
 *
 * To run a query within a React component, call `useEditKioskConfigGetShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditKioskConfigGetShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditKioskConfigGetShopQuery({
 *   variables: {
 *      shopId: // value for 'shopId'
 *   },
 * });
 */
export function useEditKioskConfigGetShopQuery(baseOptions: Apollo.QueryHookOptions<EditKioskConfigGetShopQuery, EditKioskConfigGetShopQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EditKioskConfigGetShopQuery, EditKioskConfigGetShopQueryVariables>(EditKioskConfigGetShopDocument, options);
      }
export function useEditKioskConfigGetShopLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EditKioskConfigGetShopQuery, EditKioskConfigGetShopQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EditKioskConfigGetShopQuery, EditKioskConfigGetShopQueryVariables>(EditKioskConfigGetShopDocument, options);
        }
export type EditKioskConfigGetShopQueryHookResult = ReturnType<typeof useEditKioskConfigGetShopQuery>;
export type EditKioskConfigGetShopLazyQueryHookResult = ReturnType<typeof useEditKioskConfigGetShopLazyQuery>;
export type EditKioskConfigGetShopQueryResult = Apollo.QueryResult<EditKioskConfigGetShopQuery, EditKioskConfigGetShopQueryVariables>;
export const EditKioskConfigUpdateKioskConfigDocument = gql`
    mutation EditKioskConfigUpdateKioskConfig($kioskConfig: kioskConfig_insert_input!) {
  insert_kioskConfig_one(
    object: $kioskConfig
    on_conflict: {constraint: kioskConfig_shopId_key, update_columns: [colorScheme, color, homeImageUrl, shouldPayAtCashRegister]}
  ) {
    __typename
  }
}
    `;
export type EditKioskConfigUpdateKioskConfigMutationFn = Apollo.MutationFunction<EditKioskConfigUpdateKioskConfigMutation, EditKioskConfigUpdateKioskConfigMutationVariables>;

/**
 * __useEditKioskConfigUpdateKioskConfigMutation__
 *
 * To run a mutation, you first call `useEditKioskConfigUpdateKioskConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditKioskConfigUpdateKioskConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editKioskConfigUpdateKioskConfigMutation, { data, loading, error }] = useEditKioskConfigUpdateKioskConfigMutation({
 *   variables: {
 *      kioskConfig: // value for 'kioskConfig'
 *   },
 * });
 */
export function useEditKioskConfigUpdateKioskConfigMutation(baseOptions?: Apollo.MutationHookOptions<EditKioskConfigUpdateKioskConfigMutation, EditKioskConfigUpdateKioskConfigMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditKioskConfigUpdateKioskConfigMutation, EditKioskConfigUpdateKioskConfigMutationVariables>(EditKioskConfigUpdateKioskConfigDocument, options);
      }
export type EditKioskConfigUpdateKioskConfigMutationHookResult = ReturnType<typeof useEditKioskConfigUpdateKioskConfigMutation>;
export type EditKioskConfigUpdateKioskConfigMutationResult = Apollo.MutationResult<EditKioskConfigUpdateKioskConfigMutation>;
export type EditKioskConfigUpdateKioskConfigMutationOptions = Apollo.BaseMutationOptions<EditKioskConfigUpdateKioskConfigMutation, EditKioskConfigUpdateKioskConfigMutationVariables>;