import React, { memo, useCallback } from "react";
import { CirclePicker, ColorResult } from "react-color";

import { FormItemProps } from "components/antd/Form";
import { grey } from "constants/colors";
import { EditKioskConfigFormItem } from "pages/EditKioskConfig/EditKioskConfigForm/useEditKioskConfigForm";

const colorMap = {
  light: grey[2],
  dark: grey[7],
};

const colors = Object.values(colorMap);

type Props = {
  color: string;
  setColor: (color: string) => void;
} & Omit<FormItemProps, "children" | "name">;

export const ColorSchemaField = memo<Props>(({ color, setColor, ...props }) => {
  const onChangeComplete = useCallback(
    ({ hex }: ColorResult) => {
      const color = Object.entries(colorMap).find(([_, value]) => value === hex);
      setColor(color?.[0] ?? "red");
    },
    [setColor],
  );

  return (
    <EditKioskConfigFormItem.NonProperty label="カラースキーマ" {...props}>
      <CirclePicker
        color={colorMap[color as keyof typeof colorMap]}
        colors={colors}
        onChangeComplete={onChangeComplete}
      />
    </EditKioskConfigFormItem.NonProperty>
  );
});
