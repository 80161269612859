import React, { useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Alert } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";

import { message } from "components/antd/message";
import { DashboardLayout } from "components/Layout/DashboardLayout";
import { Loading } from "components/Loading";
import { ShopHeader } from "components/PageHeader/ShopHeader";
import { useSetCurrentCompanyEffect } from "hooks/useSetCurrentCompanyEffect";
import { KioskConfigSetInput } from "types/graphql";

import { EditKioskConfigForm } from "./EditKioskConfigForm/index";
import {
  useEditKioskConfigGetShopQuery,
  useEditKioskConfigUpdateKioskConfigMutation,
} from "./queries";

export const EditKioskConfig = () => {
  const { id: shopId = "" } = useParams<{ id: string }>();

  const {
    data: getShopData,
    loading,
    refetch,
    error,
  } = useEditKioskConfigGetShopQuery(shopId ? { variables: { shopId } } : { skip: true });

  const shop = getShopData?.shop_by_pk;
  const companyId = shop?.companyId;

  useSetCurrentCompanyEffect(shop);

  const [updateKioskConfigMutation, { loading: loadingUpdateKioskConfig }] =
    useEditKioskConfigUpdateKioskConfigMutation();

  const onSubmit = useCallback(
    async ({ kioskConfig }: { kioskConfig: KioskConfigSetInput }) => {
      if (companyId && shopId) {
        try {
          await updateKioskConfigMutation({
            variables: { kioskConfig: { shopId, ...kioskConfig } },
          });

          refetch();

          message.success("編集を保存しました");
        } catch (err) {
          message.error("編集の保存に失敗しました");
        }
      }
    },
    [companyId, shopId, updateKioskConfigMutation, refetch],
  );
  const navigate = useNavigate();
  const goBack = useCallback(() => navigate(-1), [navigate]);

  const onFormValidationError = useCallback(
    (_args: { formValidationError: ValidateErrorEntity }) => {
      message.error("入力内容に誤りがあります");
    },
    [],
  );

  return (
    <DashboardLayout
      title={shop?.name}
      locationBreadcrumb={{
        showShop: false,
        items: [{ name: "店舗" }],
      }}
    >
      <ShopHeader shop={shop ?? null} onBack={goBack} />
      {loading && <Loading height={300} />}
      {error && (
        <Alert
          message="通信に失敗しました"
          type="error"
          description="ネットワーク環境を確認してください"
        />
      )}
      {shop && (
        <EditKioskConfigForm
          shop={shop}
          onSubmit={onSubmit}
          onClose={goBack}
          loading={loadingUpdateKioskConfig}
          onFormValidationError={onFormValidationError}
        />
      )}
    </DashboardLayout>
  );
};
