import React, { memo } from "react";
import { Switch } from "antd";

import { FormItemProps } from "components/antd/Form";

import { EditShopFormItem } from "../useEditShopForm";

type Props = Omit<FormItemProps, "children" | "name">;

export const UseKiosk2ndField = memo<Props>(({ ...props }) => (
  <EditShopFormItem
    label="キオスク2を利用する"
    name="useKiosk2nd"
    valuePropName="checked"
    {...props}
  >
    <Switch />
  </EditShopFormItem>
));
