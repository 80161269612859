import { useCallback } from "react";

import { createFormItem, Form } from "components/antd/Form";
import { KioskConfigSetInput } from "types/graphql";

import { Shop } from "../types";

export type EditKioskConfigFormValues = KioskConfigSetInput;

const getInitialValues = (shop: Shop): EditKioskConfigFormValues => {
  const { kioskConfig } = shop;
  return {
    colorScheme: kioskConfig?.colorScheme ?? "dark",
    color: kioskConfig?.color ?? "red",
    homeImageUrl: kioskConfig?.homeImageUrl,
    shouldPayAtCashRegister: kioskConfig?.shouldPayAtCashRegister,
  };
};

export const EditKioskConfigFormItem = createFormItem<EditKioskConfigFormValues>();

export const useEditKioskConfigForm = ({
  shop,
  color,
  colorScheme,
  onSubmit,
}: {
  shop: Shop;
  color: string;
  colorScheme: string;
  onSubmit: ({ kioskConfig }: { kioskConfig: KioskConfigSetInput }) => void;
}) => {
  const [form] = Form.useForm();
  const initialValues = getInitialValues(shop);

  const submit = useCallback(
    ({ homeImageUrl }: { homeImageUrl: string | null }) => {
      const formValues = form.getFieldsValue() as EditKioskConfigFormValues;

      onSubmit({
        kioskConfig: {
          ...formValues,
          color,
          colorScheme,
          homeImageUrl,
        },
      });
    },
    [color, colorScheme, form, onSubmit],
  );

  return { form, initialValues, submit };
};
