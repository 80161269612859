import React, { memo, useCallback, useState } from "react";
import { Button } from "antd";
import { ValidateErrorEntity } from "rc-field-form/es/interface";
import { isValidateErrorEntity } from "util/validation";

import { Form } from "components/antd/Form";
import { FormActions } from "components/Form/FormActions";
import { ImageField } from "components/Form/ImageField";
import { Spacer } from "components/Spacer";
import { FormContent } from "components/Template/FormTemplate";
import { ColorField } from "pages/EditKioskConfig/EditKioskConfigForm/ColorField";
import { ColorSchemaField } from "pages/EditKioskConfig/EditKioskConfigForm/ColorSchemaField";
import { ShouldPayAtCashRegisterField } from "pages/EditKioskConfig/EditKioskConfigForm/ShouldPayAtCashRegisterField";
import { KioskConfigSetInput } from "types/graphql";

import { Shop } from "../types";

import { useEditKioskConfigForm } from "./useEditKioskConfigForm";

type Props = {
  shop: Shop;
  onSubmit: ({ kioskConfig }: { kioskConfig: KioskConfigSetInput }) => void;
  onClose: () => void;
  loading: boolean;
  onFormValidationError: ({
    formValidationError,
  }: {
    formValidationError: ValidateErrorEntity;
  }) => void;
};

export const EditKioskConfigForm = memo<Props>(
  ({ shop, onSubmit, onClose, loading, onFormValidationError }) => {
    const [color, setColor] = useState(shop.kioskConfig?.color ?? "red");
    const [colorScheme, setColorScheme] = useState(shop.kioskConfig?.colorScheme ?? "dark");

    const { form, initialValues, submit } = useEditKioskConfigForm({
      shop,
      color,
      colorScheme,
      onSubmit,
    });

    const [uploadImage, setUploadImage] = useState(initialValues.homeImageUrl ?? null);

    const handleSubmit = useCallback(async () => {
      try {
        await form.validateFields();
        submit({ homeImageUrl: uploadImage });
      } catch (e) {
        if (isValidateErrorEntity(e)) {
          onFormValidationError({ formValidationError: e });
        }
      }
    }, [form, submit, uploadImage, onFormValidationError]);

    return (
      <>
        <FormContent>
          <Form name="shop" form={form} layout="vertical" initialValues={initialValues}>
            <ImageField
              image={uploadImage}
              setUploadImage={(image) => setUploadImage(image)}
              uploadImageApiKey="kioskHomeImage"
              formName="kioskConfig"
              label="ホーム画像"
              width={300}
              height={400}
            />
            <ColorSchemaField color={colorScheme} setColor={setColorScheme} />
            <ColorField color={color} setColor={setColor} />
            <ShouldPayAtCashRegisterField />
          </Form>
        </FormContent>
        <Spacer height={24} />
        <FormActions>
          <Button onClick={onClose}>キャンセル</Button>
          <Button type="primary" onClick={handleSubmit} loading={loading}>
            更新
          </Button>
        </FormActions>
      </>
    );
  },
);
