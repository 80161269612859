import React, { memo, useMemo } from "react";
import { Link, useLocation } from "react-router-dom";
import { Menu } from "antd";

import { PageHeader } from "components/antd/PageHeader";
import { useCanAccess } from "hooks/useCanAccess";
import { Shop as ShopType } from "types/graphql";

type Props = {
  shop: Pick<ShopType, "name" | "shopId"> | null;
  onBack: () => void;
};

export const ShopHeader = memo<Props>(({ shop, onBack }) => {
  const { pathname } = useLocation();
  const { canAccess } = useCanAccess();

  const selectedKey = pathname.split("/")[3];
  const selectedKeys = useMemo(() => (selectedKey ? [selectedKey] : []), [selectedKey]);

  return (
    <>
      <PageHeader onBack={onBack} title={shop?.name ?? ""} />
      {shop && (
        <Menu selectedKeys={selectedKeys} mode="horizontal">
          <Menu.Item key="edit">
            <Link to={`/shop/${shop.shopId}/edit`}>店舗</Link>
          </Menu.Item>
          <Menu.Item key="businessOperationHour">
            <Link to={`/shop/${shop.shopId}/businessOperationHour/edit`}>営業時間帯</Link>
          </Menu.Item>
          {canAccess("editCashRegisterConfig") && (
            <Menu.Item key="cashRegisterConfig">
              <Link to={`/shop/${shop.shopId}/cashRegisterConfig/edit`}>レジ設定</Link>
            </Menu.Item>
          )}
          {canAccess("editKioskConfig") && (
            <Menu.Item key="kioskConfig">
              <Link to={`/shop/${shop.shopId}/kioskConfig/edit`}>キオスク2設定</Link>
            </Menu.Item>
          )}
          {canAccess("editLineChannelConfig") && (
            <Menu.Item key="lineChannelConfig">
              <Link to={`/shop/${shop.shopId}/lineChannelConfig/edit`}>LINE 連携設定</Link>
            </Menu.Item>
          )}
          {canAccess("featureFlags") && (
            <Menu.Item key="featureFlag">
              <Link to={`/shop/${shop.shopId}/featureFlag`}>フィーチャーフラグ</Link>
            </Menu.Item>
          )}
          {canAccess("editGoogleMapConfig") && (
            <Menu.Item key="googleMapConfig">
              <Link to={`/shop/${shop.shopId}/googleMapConfig/edit`}>Google マップ設定</Link>
            </Menu.Item>
          )}
          {canAccess("editDiniiPayConfig") && (
            <Menu.Item key="diniiPayConfig">
              <Link to={`/shop/${shop.shopId}/diniiPayConfig/edit`}>DINII Pay 設定</Link>
            </Menu.Item>
          )}
        </Menu>
      )}
    </>
  );
});
