import React, { memo, useCallback } from "react";
import { CirclePicker, ColorResult } from "react-color";

import { FormItemProps } from "components/antd/Form";
import { EditKioskConfigFormItem } from "pages/EditKioskConfig/EditKioskConfigForm/useEditKioskConfigForm";

const colorMap = {
  red: "#f44336",
  indigo: "#3f51b5",
  teal: "#009688",
  green: "#4caf50",
  orange: "#ff9800",
  brown: "#795548",
};

const colors = Object.values(colorMap);

type Props = {
  color: string;
  setColor: (color: string) => void;
} & Omit<FormItemProps, "children" | "name">;

export const ColorField = memo<Props>(({ color, setColor, ...props }) => {
  const onChangeComplete = useCallback(
    ({ hex }: ColorResult) => {
      const color = Object.entries(colorMap).find(([_, value]) => value === hex);
      setColor(color?.[0] ?? "red");
    },
    [setColor],
  );

  return (
    <EditKioskConfigFormItem.NonProperty label="カラー" {...props}>
      <CirclePicker
        color={colorMap[color as keyof typeof colorMap]}
        colors={colors}
        onChangeComplete={onChangeComplete}
      />
    </EditKioskConfigFormItem.NonProperty>
  );
});
